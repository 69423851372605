import Head from 'next/head'
import React, {useState, useEffect, useContext} from 'react';
import Layout from '../../components/Layout'
import {getSession, useSession} from "next-auth/react";
import {
    delay,
    getMyBookmarkedPrintoutIds,
    getMyFollowedPublicationIds,
    getPublicationPrintouts
} from '@/lib/api';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router'
import CategoryHeader from '@/components/CategoryHeader';
import Col  from "react-bootstrap/Col";
import Row  from "react-bootstrap/Row";
import Container  from "react-bootstrap/Container";
import {AppContext} from '@/context/AppContext';
import SkeletonCard from '@/components/skeletons/SkeletonCard';
import PrintoutSkeletonCard from '@/components/skeletons/PrintoutSkeletonCard';
import {useFollowing} from '@/context/FollowingContext';
import {useBookmark} from '@/context/BookmarkContext';

const PrintoutCard = dynamic(() => import('@/components/partial/printoutCard'));


export default function Publication() {

    const { data: session, status } = useSession();
    const router = useRouter();
    const { handlePrint } = useContext(AppContext);
    const { followedPublications } = useFollowing();
    const { bookmarkedPrintouts } = useBookmark();


    const { slug } = router.query

    const [publication, setPublication] = useState({});
    const [publicationPrintouts, setPublicationPrintouts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const currentURL = process.env.NEXTAUTH_URL + router.asPath
    const [dateOrder, setDateOrder] = useState('asc');
    const [sortArrow, setSortArrow] = useState('↓');
    const [ogData, setOgData] = useState({
        "currentURL": currentURL,
        "previewImage": publication?.logo,
        "pageTitle": publication?.name
    });

    const fetchPublicationPrintouts = async () => {
//        setIsLoading(true);
        const publicationsRes = await getPublicationPrintouts(slug);
        setPublication(publicationsRes?.publication || {});
        setPublicationPrintouts(publicationsRes?.printouts || []);

        if (publicationsRes.printouts.length !== 0) {
            await delay(300);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!slug) {
            router.push("/")
        }

        fetchPublicationPrintouts().then(() => {
            setOgData(state => ({
                ...state,
                previewImage: publication?.logo,
                pageTitle: publication?.name
            }))
        });

        sortByDate();

    }, [])

    const sortPrintouts = (key, order = 'asc') => {
        const sortedPrintouts = [...publicationPrintouts].sort((a, b) => {
            const aValue = key.split('.').reduce((o, i) => o[i], a);
            const bValue = key.split('.').reduce((o, i) => o[i], b);
            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setPublicationPrintouts(sortedPrintouts);
    };

    const handleActiveFilter = (e) => {
        const activeFilter = document.querySelector('.activefilter');
        if (activeFilter) {
            activeFilter.classList.remove('activefilter');
        }
        if (e) {
            e.target.classList.add('activefilter');
        }
    };

    const sortByDate = (e) => {
        if (dateOrder === 'dsc') {
            sortPrintouts('published_at', 'asc');
            setDateOrder('asc');
            setSortArrow("↑");
        } else {
            sortPrintouts('published_at', 'dsc');
            setDateOrder('dsc');
            setSortArrow("↓");
        }
        handleActiveFilter(e);
    };

    const sortByNameAsc = (e) => {
        sortPrintouts('week.name', 'asc');
        handleActiveFilter(e);
    };

    const sortByNameDesc = (e) => {
        sortPrintouts('week.name', 'dsc');
        handleActiveFilter(e);
    };

    return (
        <Layout ogData={ogData}>
            <Head>
                <title>Weekly Torah Parsha Insights</title>
            </Head>
            {slug ?

                <>
                    <CategoryHeader
                        userId={session?.user?.id}
                        publication={publication}
                        followedPubs={followedPublications}
                        isPublicationPage={true}
                    />

                    <section className="filter-block filter-block1">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="language-filter categorysort-filter">
                                        <label>Sort by: <span role="button" className="activefilter" onClick={sortByDate}>{sortArrow} Date</span><span role="button" onClick={sortByNameAsc}>A-Z</span><span role="button" onClick={sortByNameDesc}>Z-A</span></label>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="section-space">
                        <div className="container">
                            <div className="printthumb_outer home_printthumb_outer printout-thumbnails mb-4 mrt-4">
                                <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5" id="thumbnails">

                                    {
                                        isLoading && publicationPrintouts.length === 0 ? (
                                            <SkeletonCard length={6} Component={PrintoutSkeletonCard}/>
                                        ) : publicationPrintouts && publicationPrintouts.length > 0 && publicationPrintouts.map((printout) => {

                                            if (printout.week?.name) {
                                                return <PrintoutCard
                                                    key={printout.id}
                                                    printout={printout}
                                                    handlePrint={handlePrint}
                                                    showWeekName={true}
                                                    isHomePage={false}
                                                    isBookmarkPage={false}
                                                    isPublicationPage={true}
                                                    bookmarkedPrints={bookmarkedPrintouts}
                                                    followedPubs={followedPublications}
                                                />
                                            }
                                        })
                                    }
                                    <iframe id={"pdf-frame"} hidden={true}></iframe>
                                </div>
                            </div>
                        </div>
                        {publicationPrintouts && publicationPrintouts.length <= 0 &&
                            <div className="no_publicationall_text mt-5"> No printouts found with this publication</div>
                        }
                    </section>
                </>

                : ""}
        </Layout>
    )

}

export async function getServerSideProps(context) {
    const session = await getSession({ req: context.req });

    return {
        props: {
            session
        },
    };
}

