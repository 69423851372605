import Col  from "react-bootstrap/Col";
import Row  from "react-bootstrap/Row";
import Container  from "react-bootstrap/Container";
import React, {useState, useContext} from "react";

import Image from "next/image";
import {AppContext} from '@/context/AppContext';
import FollowingStar from '@/components/followings/FollowingStar';
import LoginModal from '@/components/partial/LoginModal';
import {useFollowing} from '@/context/FollowingContext';
import SkeletonCard from '@/components/skeletons/SkeletonCard';
import PublicationPageLogoSkeletonCard from '@/components/skeletons/PublicationPageLogoSkeletonCard';

function CategoryHeader({ userId, publication, followedPubs }) {
    const { customImageLoader } = useContext(AppContext);
    const { isPublicationFollowed } = useFollowing();

    const [showFollowModal, setShowFollowModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [action, setAction] = useState('');


    const followStar = () => {
        return (
            <FollowingStar
                publicationId={publication.id}
                publicationName={publication.name}
                following={isPublicationFollowed(followedPubs, publication.id)}
                userId={userId}
                setShowLoginModal={setShowLoginModal}
            />
        )
    }

    const handleLoginModalClose = () => {
        setShowLoginModal(false)
    }

    const handleFollowModalClose = () => {
        setShowFollowModal(false)
    }

    return (
        <section className="categorybanner_block">
            <Container>
                <Row>
                    <Col className="categorybanner_block_left">
                        {
                            !publication ?
                                <PublicationPageLogoSkeletonCard />
                                :
                            <Image
                            src={publication.logo}
                            alt="Description"
                            width={200}
                        height={110}
                        quality={75} // Optional: to control image quality (default is 75)
                        layout="intrinsic" // Optional: for different layout modes like 'responsive', 'fill', etc.
                            priority={false} // Optional: to prioritize this image
                    />
                        }


                    </Col>
                    <Col className="categorybanner_block_right">
                        <h4 className="d-flex">{followStar()}{publication.name}</h4>
                        <p>{publication.description}</p>
                        {!publication.website ? "" : <a href={publication.website} target='_blank'>{publication?.website}</a>}
                        <br />
                        {!publication.email ? "" : <a href={`mailto:${publication.email}`}>{publication.email}</a>}
                    </Col>
                </Row>
            </Container>

            {/*Login Modal*/}
            <LoginModal showLoginModal={showLoginModal} handleLoginModalClose={handleLoginModalClose} action={action} />

        </section>
    )
}

export default CategoryHeader
