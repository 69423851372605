import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {FaStar} from '@react-icons/all-files/fa/FaStar';
import {FaRegStar} from '@react-icons/all-files/fa/FaRegStar';
import React, {useEffect, useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import {useFollowing} from '@/context/FollowingContext';
import {useLoginAction} from '@/context/LoginActionContext';


const FollowStar = ({publicationId, publicationName, userId, setShowLoginModal, following}) => {
    const {
        toggleFollowings,
        followedPublications,
        isPublicationFollowed
    } = useFollowing();

    const {setActionType} = useLoginAction();

    const [isFollowed, setIsFollowed] = useState(isPublicationFollowed(followedPublications, publicationId));
    const [showTooltip, setShowTooltip] = useState(false);
    const FollowIcon = isFollowed ? FaStar : FaRegStar;
    const followColor = isFollowed ? '#FFB406' : undefined;

    useEffect(() => {
        setIsFollowed(isPublicationFollowed(followedPublications, publicationId));
    }, [followedPublications]);


    const handleFollowingClick = () => {
        if (!userId) {
            setActionType(`Follow ${publicationName}`);
            setShowLoginModal(true);
            return;
        }

        toggleFollowings(publicationId, isFollowed ? 'unfollow' : 'follow');
        setShowTooltip(false);
    };



    return (
        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-${publicationId}`}>
                    {isFollowed ? `Following` : `Start following`} {publicationName}
                </Tooltip>
            }
            show={showTooltip}
            onEnter={() => setShowTooltip(true)}
            onExited={() => setShowTooltip(false)}
        >
            <div className="inline-block" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                <FollowIcon
                    className="icon-hover-effect"
                    role="button"
                    style={{ color: followColor, marginRight: '10px', fontSize: '20px' }}
                    onClick={handleFollowingClick}
                />
            </div>
        </OverlayTrigger>
    );
}


export default FollowStar;
