import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PublicationPageLogoSkeletonCard = () => {
    const [screen, setScreen] = useState(1400);

    useEffect(() => {
        const handleResize = () => setScreen(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let height = 110;

    if (screen <= 350) {
        height = 70;
    }

    return (
        <div className="cols">
            <div className="printout-card">
                <div className="wrap_img" style={{ margin: '10px' }}>
                    <Skeleton className="printout_skeleton" height={height * 0.7} />
                </div>
                <div className="text-left" style={{ margin: '10px', padding: '10px' }}>
                    <Skeleton />
                    <Skeleton width="30%" />
                    <h1 className="dld-icon pr-0">
                        <Skeleton height="auto" width="15%" />
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default PublicationPageLogoSkeletonCard;
